import { useState } from 'react'
import { View, Text, TextInput, TouchableOpacity, ActivityIndicator, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styles from '../assets/styles/style'

export default function JoinGameScreen({navigation}: {navigation: any}) {
  const [formValues, setFormValue] = useState({
    name: '',
    roomCode: ''
  })
  const updateForm = (name: string, value: any) => {
    setFormValue(state => ({
      ...state,
      [name]: value
    }))
  }

  const [joinGameLoading, setLoading] = useState(false)
  const handleJoinGame = () => {
    setLoading(true)
    const ws = new WebSocket('wss://paranoia-app.com:56112')
    ws.onmessage = (e) => {
      const message = JSON.parse(e.data)
      if (message.type === 'joinGame') {
        if (message.success) {
          setLoading(false)
          if (message.answeringPlayerId) {
            navigation.navigate('Play', {
              myId: message.myId,
              roomCode: message.roomCode,
              playerData: message.playerData,
              answererId: message.answeringPlayerId,
              onlyPlayerNames: message.onlyPlayerNames,
              answers: message.currentAnswers,
              answersSubmitted: message.currentAnswers.length > 0,
              requiredDrinks: message.currentDrinkRequirement,
              ws: ws
            })
          } else {
            navigation.navigate('Lobby', {
              myId: message.myId,
              roomCode: message.roomCode,
              playerData: message.playerData,
              onlyPlayerNames: message.onlyPlayerNames,
              ws: ws
            })
          }
        } else {
          setLoading(false)
        }
      }
    }
    ws.onopen = (e) => {
      ws.send(JSON.stringify({
        type: 'joinGame',
        payload: formValues
      }))
    }
  }

  const JoinGameButtonContainer = () => {
    if (!joinGameLoading) {
      return (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={handleJoinGame}
          >
            <Text style={styles.buttonText}>
              Join
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={() => navigation.navigate('Home')}
          >
            <Text style={styles.buttonText}>
              Back
            </Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={styles.buttonContainer}>
          <ActivityIndicator size="large" />
        </View>
      )
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.outerContent}>
        <View style={styles.innerContent}>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>
              Join Game
            </Text>
          </View>
          <View style={styles.formContainer}>
            { /* Name */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Name
              </Text>
              <TextInput
                style={styles.formText}
                onChangeText={(value) => updateForm('name', value)}
                value={formValues.name}
              />
            </View>
            { /* Game Code */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Game Code
              </Text>
              <TextInput
                style={styles.formText}
                onChangeText={(value) => updateForm('roomCode', value.toUpperCase())}
                value={formValues.roomCode}
              />
            </View>
          </View>
          <JoinGameButtonContainer />
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}