import { useState } from 'react'
import { View, Text, TextInput, Switch, TouchableOpacity, ActivityIndicator, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Slider } from '@miblanchard/react-native-slider'
import styles from '../assets/styles/style'

export default function HostGameScreen({navigation}: {navigation: any}) {
  const [formValues, setFormValue] = useState({
    name: '',
    onlyPlayerNames: false,
    redrawPenalty: false,
    guiltFree: false,
    spiciness: 3
  })
  const updateForm = (name: string, value: any) => {
    setFormValue(formValues => ({
      ...formValues,
      [name]: value
    }))
  }

  const spicinessThumb = () => (
    <View>
      <Text style={styles.formSliderPepper}>🌶️</Text>
    </View>
  )

  const [hostGameLoading, setLoading] = useState(false)
  const handleHostGame = () => {
    setLoading(true)
    const ws = new WebSocket('wss://paranoia-app.com:56112')
    ws.onmessage = (e) => {
      const message = JSON.parse(e.data)
      if (message.type === 'createGame') {
        if (message.success) {
          setLoading(false)
          navigation.navigate('Lobby', {
            myId: message.myId,
            roomCode: message.roomCode,
            playerData: message.playerData,
            onlyPlayerNames: message.onlyPlayerNames,
            ws: ws
          })
        } else {
          setLoading(false)
        }
      }
    }
    ws.onopen = (e) => {
      ws.send(JSON.stringify({
        type: 'createGame',
        payload: formValues
      }))
    }
  }

  const HostGameButtonContainer = () => {
    if (!hostGameLoading) {
      return (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={handleHostGame}
          >
            <Text style={styles.buttonText}>
              Host Game
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={() => navigation.navigate('Home')}
          >
            <Text style={styles.buttonText}>
              Back
            </Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={styles.buttonContainer}>
          <ActivityIndicator size="large" />
        </View>
      )
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.outerContent}>
        <View style={styles.innerContent}>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>
              Host Game
            </Text>
          </View>
          <View style={styles.formContainer}>
            { /* Name */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Name
              </Text>
              <TextInput
                style={styles.formText}
                onChangeText={(value) => updateForm('name', value)}
                value={formValues.name}
              />
            </View>
            { /* Only Player Names */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Use Player Names Only
              </Text>
              <Switch
                style={styles.formSwitch}
                onValueChange={(value) => updateForm('onlyPlayerNames', value)}
                value={formValues.onlyPlayerNames}
              />
            </View>
            { /* Redraw Penalty */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Redraw Penalty
              </Text>
              <Switch
                style={styles.formSwitch}
                onValueChange={(value) => updateForm('redrawPenalty', value)}
                value={formValues.redrawPenalty}
              />
            </View>
            { /* Guilt Free */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Guilt Free
              </Text>
              <Switch
                style={styles.formSwitch}
                onValueChange={(value) => updateForm('guiltFree', value)}
                value={formValues.guiltFree}
              />
            </View>
            { /* Spiciness */ }
            <View style={styles.formRow}>
              <Text style={styles.formLabel}>
                Maximum Spiciness
              </Text>
              <View style={styles.formSlider}>
                <Slider
                  onValueChange={(value) => updateForm('spiciness', value)}
                  value={formValues.spiciness}
                  minimumValue={1}
                  maximumValue={3}
                  step={1}
                  trackClickable={true}
                  minimumTrackTintColor='#b33939'
                  maximumTrackTintColor='#218c74'
                  renderThumbComponent={spicinessThumb}
                />
              </View>
            </View>
          </View>
          <HostGameButtonContainer />
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}