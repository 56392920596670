import { useState } from 'react'
import { View, Text, TouchableOpacity, TextInput, ActivityIndicator, StyleSheet, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import RNPickerSelect from 'react-native-picker-select'
import styles from '../assets/styles/style'

export default function PlayGameScreen({route, navigation}: {route: any, navigation: any}) {
  const [state, setState] = useState({
    roomCode: route.params.roomCode || '',
    myId: route.params.myId || '',
    playerData: route.params.playerData || {},
    ws: route.params.ws || null,
    onlyPlayerNames: route.params.onlyPlayerNames || false,
    answererId: route.params.answererId || null,
    answers: route.params.answers || Array(),
    answersSubmitted: route.params.answersSubmitted || false,
    question: null,
    questionNumAnswers: 1,
    requiredDrinks: route.params.requiredDrinks || null,
    drinksSubmitted: Array(),
    decisionSubmitted: false,
    drinksToSubmit: '',
    answersToSubmit: Array(),
    currentDrinks: Array(),
    currentPasses: Array(),
    buttonsLoading: false,
    questionRevealed: false
  })
  const handleChange = (name: string, value: any) => {
    setState(state => ({
      ...state,
      [name]: value
    }))
  }

  state.ws.onmessage = (e : any) => {
    const message = JSON.parse(e.data)
    if (message.type === 'questionAnswered') {
      const answerData = message.answerData
      handleChange('requiredDrinks', answerData.drinks)
      handleChange('answers', answerData.answers)
      handleChange('answersSubmitted', true)
    } else if (message.type === 'turnCompleted') {
      handleChange('answererId', message.newAnswererId)
      handleChange('requiredDrinks', 2)
      handleChange('answers', Array()),
      handleChange('answersSubmitted', false),
      handleChange('question', null)
      handleChange('questionNumAnswers', 1)
      handleChange('drinksSubmitted', Array())
      handleChange('currentDrinks', Array())
      handleChange('currentPasses', Array())
      handleChange('decisionSubmitted', false)
      handleChange('questionRevealed', false)
    } else if (message.type === 'startMyTurn') {
      handleChange('answersToSubmit', Array())
      handleChange('drinksToSubmit', '')
      handleChange('question', message.question)
      handleChange('questionNumAnswers', message.questionNumAnswers)
    } else if (message.type === 'redraw') {
      if (message.success === true) {
        handleChange('answersToSubmit', Array())
        handleChange('question', message.newQuestion)
        handleChange('questionNumAnswers', message.newQuestionNumAnswers)
      }
      handleChange('buttonsLoading', false)
    } else if (message.type === 'redrawUsed') {
      const playerData = state.playerData
      playerData[message.playerId].redrawCount++
      handleChange('playerData', playerData)
    } else if (message.type === 'receiveQuestion') {
      handleChange('question', message.question)
    } else if (message.type === 'drinksSubmitted') {
      const playerData = state.playerData
      const drinkData = message.drinkData
      const curDrinks = Number(playerData[drinkData.playerId].drinkCount)
      playerData[drinkData.playerId].drinkCount = curDrinks + Number(drinkData.drinks)
      handleChange('playerData', playerData)
      const drinksSubmitted = state.drinksSubmitted
      drinksSubmitted.push(drinkData.playerId)
      handleChange('drinksSubmitted', drinksSubmitted)
      const currentDrinks = state.currentDrinks.slice()
      currentDrinks.push(drinkData.playerId)
      handleChange('currentDrinks', currentDrinks)
    } else if (message.type === 'passSubmitted') {
      const passData = message.passData
      const currentPasses = state.currentPasses.slice()
      currentPasses.push(passData.playerId)
      handleChange('currentPasses', currentPasses)
    } else if (message.type === 'newPlayer') {
      const playerData = state.playerData
      playerData[message.newPlayerId] = message.newPlayerData
      handleChange('playerData', playerData)
    } else if (message.type === 'playerLeft') {
      const playerData = state.playerData
      playerData[message.oldPlayerId].hasQuit = true
      if (message.newHostId) {
        playerData[message.newHostId].isHost = true
      }
      handleChange('playerData', playerData)
    } else if (message.type === 'submitPass') {
      if (message.success) {
        handleChange('decisionSubmitted', true)
      }
      handleChange('buttonsLoading', false)
    } else if (message.type === 'submitDrinks') {
      if (message.success) {
        handleChange('decisionSubmitted', true)
      }
      handleChange('buttonsLoading', false)
    } else if (message.type === 'completeTurn') {
      handleChange('buttonsLoading', false)
    } else if (message.type === 'answerQuestion') {
      handleChange('buttonsLoading', false)
    } else if (message.type === 'revealQuestion') {
      handleChange('buttonsLoading', false)
    } else if (message.type === 'questionRevealed') {
      handleChange('questionRevealed', true)
    }
  }

  state.ws.onclose = (e: any) => {
    navigation.navigate('Home')
  }

  const handleSubmitAnswer = () => {
    handleChange('buttonsLoading', true)
    state.ws.send(JSON.stringify({
      type: 'answerQuestion',
      payload: {
        answers: state.answersToSubmit,
        drinks: state.drinksToSubmit
      }
    }))
  }

  const handleRedraw = () => {
    handleChange('buttonsLoading', true)
    state.ws.send(JSON.stringify({
      type: 'redraw'
    }))
  }

  const PlayArea = () => {
    let playAreaInner = null
    if (state.myId !== state.answererId) {
      // Drinkers
      if (state.answersSubmitted) {
        if (state.question) {
          playAreaInner = SeeQuestion()
        } else if (state.questionRevealed) {
          playAreaInner = QuestionRevealed()
        } else {
          if (state.decisionSubmitted) {
            playAreaInner = DrinkStatus()
          } else {
            playAreaInner = SeeAnswer()
          }
        }
      } else {
        playAreaInner = WaitingForAnswers()
      }
    } else {
      // Answerer
      if (!state.question) {
        playAreaInner = (
          <ActivityIndicator size="large" />
        )
      } else if (state.questionRevealed) {
        playAreaInner = QuestionRevealed()
      } else if (state.answersSubmitted) {
        playAreaInner = DrinkStatus()
      } else {
        playAreaInner = AnswerQuestion()
      }
    }
    return (
      <View style={styles.playAreaInner}>
        {playAreaInner}
      </View>
    )
  }

  const questionRevealedButtons = () => {
    if (state.answererId !== state.myId) {
      return (
        <Text>Waiting on next turn...</Text>
      )
    } else {
      return (
        <TouchableOpacity
          style={styles.button}
          onPress={handleEndTurn}
        >
          <Text style={styles.buttonText}>
            End Turn
          </Text>
        </TouchableOpacity>
      )
    }
  }

  const QuestionRevealed = () => {
    if (state.answererId === state.myId) {
      return (
        <View>
          <Text>Question Revealed!</Text>
          <Text>{state.question}</Text>
          {questionRevealedButtons()}
        </View>
      )
    } else {
      return (
        <View>
          <Text>Question Revealed!</Text>
          {questionRevealedButtons()}
        </View>
      )
    }
  }

  const currentDrinksList = () => {
    const drinkers = []
    for (const playerId of state.currentDrinks) {
      const player = state.playerData[playerId]
      drinkers.push(
        <Text key={"d_" + playerId}>{player.name}</Text>
      )
    }
    return (drinkers)
}

  const currentPassList = () => {
      const passers = []
      for (const playerId of state.currentPasses) {
        const player = state.playerData[playerId]
        passers.push(
          <Text key={"p_" + playerId}>{player.name}</Text>
        )
      }
      return (passers)
  }

  const handleRevealQuestion = () => {
    state.ws.send(JSON.stringify({
      type: 'revealQuestion'
    }))
  }

  const drinkStatusButtons = () => {
    if (state.answererId !== state.myId) {
      return (
        <Text>Waiting on Answerer...</Text>
      )
    } else {
      return (
        <TouchableOpacity
          style={styles.button}
          onPress={handleRevealQuestion}
        >
          <Text style={styles.buttonText}>
            Reveal Question
          </Text>
        </TouchableOpacity>
      )
    }
  }

  const DrinkStatus = () => {
    return (
      <View>
        <View>
          <View style={{paddingBottom: 16}}>
            <Text>Drank:</Text>
            {currentDrinksList()}
          </View>
          <View style={{paddingBottom: 16}}>
            <Text>Passed:</Text>
            {currentPassList()}
          </View>
        </View>
        <View>
          {drinkStatusButtons()}
        </View>
      </View>
    )
  }

  const handleSelectAnswer = (value: any, index: any) => {
    const currentAnswers = state.answersToSubmit.slice()
    let playerName = null
    if (value) {
      playerName = state.playerData[value].name
    }
    if (currentAnswers.length <= index) {
      currentAnswers.push(playerName)
    } else {
      currentAnswers[index] = playerName
    }
    handleChange('answersToSubmit', currentAnswers)
  }

  const handleInputAnswer = (value: any, index: any) => {
    const currentAnswers = state.answersToSubmit.slice()
    const playerName = value
    if (currentAnswers.length <= index) {
      currentAnswers.push(playerName)
    } else {
      currentAnswers[index] = playerName
    }
    handleChange('answersToSubmit', currentAnswers)
  }

  const createPlayerSelectOptions = () => {
    const playerSelectOptions = []
    for (const playerId in state.playerData) {
      const player = state.playerData[playerId]
      if (!player.hasQuit) {
        playerSelectOptions.push({value: playerId, label: player.name})
      }
    }
    return playerSelectOptions
  }

  const answerInputs = () => {
    const answerInputs = []
    for (let ii = 0; ii < state.questionNumAnswers; ii++) {
      if (state.onlyPlayerNames) {
        answerInputs.push(
          <View
            key={'ps_' + ii}
            style={styles.formPickerRow}
          >
            <RNPickerSelect
              onValueChange={(value: any) => handleSelectAnswer(value, ii)}
              items={createPlayerSelectOptions()}
              style={pickerSelectStyles}
            />
          </View>
        )
      } else {
        answerInputs.push(
          <View
            key={'pi_' + ii}
            style={styles.formPickerRow}
          >
            <TextInput
              style={styles.formText}
              onChangeText={(value: any) => handleInputAnswer(value, ii)}
              value={state.answersToSubmit[ii] || ''}
            />
          </View>
        )
      }
    }
    return (answerInputs)
  }

  const createDrinkSelectOptions = () => {
    const drinkSelectOptions = []
    for (let ii = 1; ii <= 10; ii++) {
      drinkSelectOptions.push({value: ii, label: ii.toString()})
    }
    return drinkSelectOptions
  }

  const AnswerQuestion = () => {
    return (
      <View>
        <Text>{state.question}</Text>
        <View>
          {answerInputs()}
        </View>
        <View style={styles.formPickerRow}>
          <RNPickerSelect
            onValueChange={(value: number) => handleChange('drinksToSubmit', value)}
            items={createDrinkSelectOptions()}
            style={pickerSelectStyles}
            value={state.drinksToSubmit || ''}
          />
          <Text>drinks</Text>
        </View>
        <TouchableOpacity
          style={styles.button}
          onPress={handleRedraw}
        >
          <Text style={styles.buttonText}>
            Redraw
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={handleSubmitAnswer}
        >
          <Text style={styles.buttonText}>
            Submit
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  const handleEndTurn = () => {
    handleChange('buttonsLoading', true)
    state.ws.send(JSON.stringify({
      type: 'endTurn'
    }))
  }

  const mostParanoidList = () => {
    const mostParanoidText = []
    const mostParanoid = []
    for (const playerId in state.playerData) {
      const player = state.playerData[playerId]
      mostParanoid.push([player.name, player.drinkCount, playerId])
    }
    mostParanoid.sort((a, b) => { return b[1] - a[1] })
    for (const paranoidPlayer of mostParanoid) {
      mostParanoidText.push(
        <Text key={'mp_' + paranoidPlayer[2]}>
          {paranoidPlayer[0]} ({paranoidPlayer[1]})
        </Text>
      )
    }
    return (mostParanoidText)
  }

  const scaredyCatsList = () => {
    const scaredyCatsText = []
    const scaredyCats = []
    for (const playerId in state.playerData) {
      const player = state.playerData[playerId]
      scaredyCats.push([player.name, player.redrawCount, playerId])
    }
    scaredyCats.sort((a, b) => { return b[1] - a[1] })
    for (const scaredyCat of scaredyCats) {
      scaredyCatsText.push(
        <Text key={'mp_' + scaredyCat[2]}>
          {scaredyCat[0]} ({scaredyCat[1]})
        </Text>
      )
    }
    return (scaredyCatsText)
  }

  const WaitingForAnswers = () => {
    return (
      <View style={styles.waitingForAnswersContainer}>
        <View style={styles.waitingForAnswersTitle}>
          <Text style={styles.waitingForAnswersTitleText}>{state.playerData[state.answererId].name} is deciding...</Text>
        </View>
        <View>
          <View style={styles.waitingForAnswersMostParanoid}>
            <Text style={styles.waitingForAnswersMostParanoidText}>Most Paranoid: 👀</Text>
            {mostParanoidList()}
          </View>
          <View style={styles.waitingForAnswersScaredyCats}>
            <Text style={styles.waitingForAnswersMostParanoidText}>Scaredy Cats: 🙊</Text>
            {scaredyCatsList()}
          </View>
        </View>
      </View>
    )
  }

  const handleSubmitDrinks = () => {
    handleChange('buttonsLoading', true)
    state.ws.send(JSON.stringify({
      type: 'submitDrinks',
    }))
  }

  const handlePass = () => {
    handleChange('buttonsLoading', true)
    state.ws.send(JSON.stringify({
      type: 'submitPass',
    }))
  }

  const answerList = () => {
    const answerList = []
    for (let ii = 0; ii < 5; ii++) {
      const answer = state.answers[ii]
      answerList.push(
        <Text
          key={'a_' + ii}
          style={styles.seeAnswersAnswerText}
        >
          {answer}
        </Text>
      )
    }
    return (answerList)
  }

  const SeeAnswer = () => {
    return (
      <View style={styles.seeAnswersContainer}>
        <View style={styles.seeAnswersTitle}>
          <View style={styles.seeAnswersTitleTextContainer}>
            <Text style={styles.seeAnswersTitleText}>{state.playerData[state.answererId].name} has answered:</Text>
          </View>
          <View style={styles.seeAnswersAnswerTextContainer}>
            {answerList()}
          </View>
          <View style={[styles.seeAnswersTitleTextContainer, {paddingBottom: 16}]}>
            <Text style={styles.seeAnswersTitleText}>for</Text>
            <Text style={styles.seeAnswersTitleText}>{state.requiredDrinks} drinks</Text>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <Text style={[styles.seeAnswersTitleText, {paddingBottom: 8}]}>Are you paranoid?</Text>
          <TouchableOpacity
            style={styles.button}
            onPress={handleSubmitDrinks}
          >
            <Text style={styles.buttonText}>
              Yes
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={handlePass}
          >
            <Text style={styles.buttonText}>
              No
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  const SeeQuestion = () => {
    return (
      <Text>{state.question}</Text>
    )
  }

  const handleQuit = () => {
    state.ws.send(JSON.stringify({
      type: 'quitGame'
    }))
    state.ws.close()
    navigation.navigate('Home')
  }

  const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
      width: '100%',
      backgroundColor: '#ffffff'
    },
    inputAndroid: {
      width: '100%'
    },
    inputWeb: {
      width: '100%'
    }
  })

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.outerContent}>
        <TouchableOpacity
          style={styles.quitButtonContainer}
          onPress={handleQuit}
        >
          <Text style={styles.quitButton}>
            X
          </Text>
        </TouchableOpacity>
        <View style={styles.innerContent}>
          <View style={styles.playRoomCodeContainer}>
            <Text style={styles.playRoomCodeText}>
              Room Code:
            </Text>
            <Text style={styles.playRoomCodeText}>
              {state.roomCode}
            </Text>
          </View>
          {PlayArea()}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}