import React, { useCallback, useEffect, useState} from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import * as SplashScreen from 'expo-splash-screen'
import * as Font from 'expo-font'

import HomeScreen from './screens/home'
import HostGameScreen from './screens/host'
import JoinGameScreen from './screens/join'
import LobbyScreen from './screens/lobby'
import PlayGameScreen from './screens/play'

SplashScreen.preventAutoHideAsync();

const customFonts = {
  'RalewaySemiBold': require('./assets/fonts/Raleway-SemiBold.ttf'),
  'Raleway': require('./assets/fonts/Raleway-Regular.ttf')
};

const Stack = createNativeStackNavigator()

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false)

  useEffect(() => {
    async function prepare() {
      await Font.loadAsync(customFonts)
      setAppIsReady(true);
    }
    prepare()
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady) {
    return null
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer onReady={ onLayoutRootView }>
        <Stack.Navigator
          initialRouteName='Home'
          screenOptions={{ headerShown: false }}
        >
          <Stack.Screen name='Home' component={ HomeScreen } />
          <Stack.Screen name='Host' component={ HostGameScreen } />
          <Stack.Screen name='Join' component={ JoinGameScreen } />
          <Stack.Screen name='Lobby' component={ LobbyScreen } />
          <Stack.Screen name='Play' component={ PlayGameScreen } />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  )
}
