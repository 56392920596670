import { View, Text, TouchableOpacity, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styles from '../assets/styles/style'

export default function HomeScreen({navigation}: {navigation: any}) {
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.outerContent}>
        <View style={styles.innerContent}>
          <View style={styles.homeTitleContainer}>
            <Text style={styles.titleText}>
              PARANOIA 👀
            </Text>
          </View>
          <View style={styles.homeButtonContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => navigation.navigate('Host')}
            >
              <Text style={styles.buttonText}>
                Host Game
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button}
              onPress={() => navigation.navigate('Join')}
            >
              <Text style={styles.buttonText}>
                Join Game
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <Text style={styles.copyrightText}>
          ©2022 - Emily, Tony, and Kelsey 🥰
        </Text>
      </ScrollView>
    </SafeAreaView>
  )
}