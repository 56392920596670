import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1
  },
  outerContent: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: '#000000',
    borderWidth: 20,
    backgroundColor: '#f7f1e3'
  },
  innerContent: {
    flex: 1,
    width: 700,
    maxWidth: '100%',
    paddingHorizontal: 15,
    paddingVertical: 25
  },
  homeTitleContainer: {
    flex: 3,
    justifyContent: 'center'
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  titleText: {
    alignSelf: 'center',
    fontSize: 50,
    fontWeight: 'bold',
    fontFamily: 'RalewaySemiBold'
  },
  homeButtonContainer: {
    flex: 2,
    justifyContent: 'center',
    paddingVertical: 20
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 20
  },
  button: {
    alignSelf: 'center',
    width: 250,
    height: 75,
    maxWidth: '100%',
    justifyContent: 'center',
    backgroundColor: '#000000',
    marginBottom: 15
  },
  buttonText: {
    alignSelf: 'center',
    color: '#f7f1e3',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Raleway'
  },
  copyrightText: {
    alignSelf: 'flex-start',
    fontSize: 12
  },
  formContainer: {
    flex: 3,
    justifyContent: 'center'
  },
  formRow: {
    flexDirection: 'row',
    alignContent: 'space-between',
    justifyContent: 'space-evenly',
    paddingVertical: 15,
  },
  formLabel: {
    flex: 1,
    fontSize: 24,
    fontFamily: 'Raleway',
    paddingHorizontal: 10,
    alignSelf: 'center'
  },
  formText: {
    flex: 3,
    backgroundColor: '#ffffff',
    fontSize: 24,
    fontFamily: 'Raleway',
    paddingHorizontal: 10,
    alignSelf: 'center',
    height: 48
  },
  formPickerRow: {
    marginVertical: 10
  },
  formSwitch: {
    alignSelf: 'center'
  },
  formSlider: {
    flex: 1,
    alignSelf: 'center',
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'stretch',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  formSliderPepper: {
    fontSize: 32
  },
  lobbyTitleText: {
    alignSelf: 'center',
    fontSize: 35,
    fontWeight: 'bold',
    fontFamily: 'RalewaySemiBold'
  },
  playerList: {
    flex: 2,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center'
  },
  playerNameContainer: {
    flexBasis: '50%',
  },
  playerNameInnerContainer: {
    marginLeft: '25%'
  },
  playerName: {
    fontSize: 24,
    fontFamily: 'Raleway'
  },
  playerNameHidden: {
    fontSize: 24,
    fontFamily: 'Raleway',
    color: 'transparent'
  },
  waitingTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  waitingText: {
    fontSize: 24,
    fontFamily: 'Raleway',
    paddingBottom: 15
  },
  playRoomCodeContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  playRoomCodeText: {
    alignSelf: 'center',
    fontSize: 24,
    fontFamily: 'Raleway'
  },
  playAreaInner: {
    flex: 3,
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  waitingForAnswersContainer: {
    flex: 1,
  },
  waitingForAnswersTitle: {
    flex: 2,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  waitingForAnswersTitleText: {
    fontSize: 36,
    fontFamily: 'Raleway',
  },
  waitingForAnswersMostParanoid: {
    flex: 1
  },
  waitingForAnswersMostParanoidText: {
    fontSize: 24,
    fontFamily: 'Raleway',
  },
  waitingForAnswersScaredyCats: {
    flex: 1
  },
  waitingForAnswersScaredyCatsText: {
    fontSize: 24,
    fontFamily: 'Raleway',
  },
  seeAnswersContainer: {
    flex: 1,
    alignContent: 'center',
  },
  seeAnswersTitle: {
    flex: 2,
    alignSelf: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30
  },
  seeAnswersTitleTextContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  seeAnswersTitleText: {
    alignSelf: 'center',
    fontSize: 36,
    fontFamily: 'Raleway',
  },
  seeAnswersAnswerTextContainer: {
    flex: 2,
    justifyContent: 'center',
    paddingVertical: 25
  },
  seeAnswersAnswerText: {
    alignSelf: 'center',
    fontSize: 48,
    fontWeight: 'bold',
    fontFamily: 'RalewaySemiBold'
  },
  quitButtonContainer: {
    alignSelf: 'flex-end',
    justifyContent: 'center',
    backgroundColor: '#b33939',
    padding: 10,
    marginBottom: 15
  },
  quitButton: {
    alignSelf: 'flex-end',
    fontSize: 36,
    fontWeight: 'bold',
    fontFamily: 'RalewaySemiBold',
    color: '#ffffff'
  }
})