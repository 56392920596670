import { useState } from 'react'
import { View, Text, TouchableOpacity, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styles from '../assets/styles/style'

export default function LobbyScreen({route, navigation}: {route: any, navigation: any}) {
  const [state, setState] = useState({
    roomCode: route.params.roomCode || '',
    myId: route.params.myId || '',
    playerData: route.params.playerData || {},
    ws: route.params.ws || null
  })
  const handleChange = (name: string, value: any) => {
    setState(state => ({
      ...state,
      [name]: value
    }))
  }

  state.ws.onmessage = (e : any) => {
    const message = JSON.parse(e.data)
    if (message.type === 'newPlayer') {
      const playerData = state.playerData
      playerData[message.newPlayerId] = message.newPlayerData
      handleChange('playerData', playerData)
    } else if (message.type === 'gameStarted') {
      setLoading(false)
      navigation.navigate('Play', {
        myId: state.myId,
        roomCode: state.roomCode,
        playerData: state.playerData,
        answererId: message.newAnswererId,
        onlyPlayerNames: message.onlyPlayerNames,
        ws: state.ws
      })
    } else if (message.type === 'playerLeft') {
      const playerData = state.playerData
      delete playerData[message.oldPlayerId]
      if (message.newHostId) {
        playerData[message.newHostId].isHost = true
      }
      handleChange('playerData', playerData)
    }
  }

  state.ws.onclose = (e: any) => {
    navigation.navigate('Home')
  }

  const PlayerList = () => {
    const playerViews = []
    for (const playerId in state.playerData) {
      const player = state.playerData[playerId]
      playerViews.push(
        <View
          key={'playerName_' + playerId}
          style={styles.playerNameContainer}
        >
          <Text style={styles.playerNameInnerContainer}>
            <Text style={(player.isHost ? styles.playerName : styles.playerNameHidden)}>
              ⭐
            </Text>
            <Text style={styles.playerName}>
              {player.name}
            </Text>
          </Text>
        </View> 
      )
    }
    return (
      <View style={styles.playerList}>
        {playerViews}
      </View>
    )
  }

  const [startGameLoading, setLoading] = useState(false)
  const handleStartGame = () => {
    setLoading(true)
    state.ws.send(JSON.stringify({
      type: 'startGame'
    }))
  }

  const handleBack = () => {
    state.ws.send(JSON.stringify({
      type: 'quitGame'
    }))
    state.ws.close()
    navigation.navigate('Home')
  }

  const StartGame = () => {
    if (state.playerData[state.myId].isHost) {
      return (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={handleStartGame}
          >
            <Text style={styles.buttonText}>
              Start Game
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={handleBack}
          >
            <Text style={styles.buttonText}>
              Back
            </Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={styles.waitingTextContainer}>
          <Text style={styles.waitingText}>
            Waiting for Host...
          </Text>
          <TouchableOpacity
            style={styles.button}
            onPress={handleBack}
          >
            <Text style={styles.buttonText}>
              Back
            </Text>
          </TouchableOpacity>
        </View>
      )
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.outerContent}>
        <View style={styles.innerContent}>
          <View style={styles.titleContainer}>
            <Text style={styles.lobbyTitleText}>
              Room Code:
            </Text>
            <Text style={styles.lobbyTitleText}>
              {state.roomCode}
            </Text>
          </View>
          <PlayerList />
          <StartGame />
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}